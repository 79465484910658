import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import navigation from '../../constants/navigation'

function DiscordIconButton(props: IProps) {
  const { color, backgroundColor, className } = props

  return (
    <div className={className}>
      <a className="leading-none" href={navigation.discordUrl}>
        <div
          className={`text-${color} bg-${backgroundColor} h-10 w-10 flex justify-center items-center rounded-full`}
        >
          <FontAwesomeIcon icon={faDiscord} className="h-5" />
        </div>
      </a>
    </div>
  )
}

interface IProps {
  color?: string
  backgroundColor?: string
  className: string
}

DiscordIconButton.defaultProps = {
  color: 'primary',
  backgroundColor: 'accent',
  className: '',
}

export default DiscordIconButton
