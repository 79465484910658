import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import navigation from '../../constants/navigation'

function TwitterIconButton(props: IProps) {
  const { color, backgroundColor, className } = props

  return (
    <div className={className}>
      <a className="leading-none" href={navigation.twitterUrl}>
        <div
          className={`text-${color} bg-${backgroundColor} h-10 w-10 flex justify-center items-center rounded-full`}
        >
          <FontAwesomeIcon icon={faTwitter} className="h-5" />
        </div>
      </a>
    </div>
  )
}

interface IProps {
  color?: string
  backgroundColor?: string
  className: string
}

TwitterIconButton.defaultProps = {
  color: 'light',
  backgroundColor: 'secondary',
  className: '',
}

export default TwitterIconButton
