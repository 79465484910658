import PokoClubText from '../../components/PokoClubText'
import DiscordIconButton from '../../components/social-buttons/DiscordIconButton'
import InstagramIconButton from '../../components/social-buttons/InstagramIconButton'
import TwitterIconButton from '../../components/social-buttons/TwitterIconButton'

function ComingSoon() {
  return (
    <div className="flex flex-col justify-center w-screen h-screen">
      <div className="flex justify-center pb-4 pt-10">
        <PokoClubText />
      </div>
      <div className="flex justify-center py-2">
        <h4>coming soon</h4>
      </div>
      <div className="flex justify-center pt-10">
        <TwitterIconButton className="m-2" />
        <InstagramIconButton className="m-2" />
        <DiscordIconButton className="m-2" />
      </div>
    </div>
  )
}

export default ComingSoon
