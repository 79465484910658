function PokoClubText() {
  return (
    <div>
      <h1>
        <span className="text-primary">poko</span>
        <span className="text-accent">.</span>
        <span className="text-secondary">club</span>
      </h1>
    </div>
  )
}

export default PokoClubText
