import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import navigation from '../../constants/navigation'

function InstagramIconButton(props: IProps) {
  const { color, backgroundColor, className } = props

  return (
    <div className={className}>
      <a className="leading-none" href={navigation.instagramUrl}>
        <div
          className={`text-${color} bg-${backgroundColor} h-10 w-10 flex justify-center items-center rounded-full`}
        >
          <FontAwesomeIcon icon={faInstagram} className="h-5" />
        </div>
      </a>
    </div>
  )
}

interface IProps {
  color?: string
  backgroundColor?: string
  className: string
}

InstagramIconButton.defaultProps = {
  color: 'light',
  backgroundColor: 'primary',
  className: '',
}

export default InstagramIconButton
