import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './App.css'
import ComingSoon from './views/ComingSoon'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<ComingSoon />} />
        <Route path="/" element={<ComingSoon />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
